@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@1,300&display=swap');
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.ant-layout-header {
    display: flex;
    position: relative;
    background-color: #fff;
    color: #141414;
    box-shadow: 0 20px 27px rgb(0 0 0/5%);
    flex-direction: row;
    padding: 0 50px;
    height: 80px;
}

.logoTitle {
    font-size: 18px;
    color: #0f2a6c;
    font-family: 'Poppins';
    font-weight: bold;
}

.logoTitle div:last-child {
    position: relative;
    top: -5px
}

.logo {
    width: 100%;
}

.logo img {
    width: 60px;
}

.icon {
    width: 35px;
    height: 35px;
    padding: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon.Facebook {
    background: #3c5a9b;
    border-radius: 50%;
    overflow: hidden;
}

.icon.Youtube {
    background: #de2a27;
    border-radius: 50%;
    overflow: hidden;
}

.icon.Watsapp {
    background: #21cb42;
    border-radius: 50%;
    overflow: hidden;
}

.icon img {
    width: 70%;
}

main {
    padding: 2rem 50px;
}

footer {
    background: #0f2a6c;
    padding: 0.8rem;
    position: relative;
    bottom: 0;
    width: 100%;
}

label {
    font-weight: bold;
    font-size: 14px;
}

footer span {
    font-size: 13px;
}

@media screen and (max-width: 1400px) {
    footer {
        position: relative;
    }
}

@media screen and (max-width: 500px) {
    main {
        padding: 10px
    }
    footer {
        position: relative
    }
    .images {
        display: none;
    }
    .btn-default {
        display: none;
    }
    .ant-layout-header {
        padding: 0 10px;
    }
    .logoTitle {
        font-size: 15px;
    }
    .title-head {
        font-size: 20px !important;
    }
}